import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  error!: string | null;

  ngOnInit(): void {
      
  }

  constructor(public AuthService:AuthService, private router:Router) { }

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  submit() {
    if (this.form.valid) {
      this.AuthService.login(this.form.value.email, this.form.value.password)
      .then(data => {
        console.log(data)
        this.router.navigate(['/'])
      })
      .catch(err => console.log(err))
    }
  }
  



}
