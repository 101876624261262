import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, of } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import { Deuda } from '../interfaces/deuda';
import { ClientesService } from './clientes.service';

@Injectable({
  providedIn: 'root',
})
export class DeudasService {
  constructor(
    private firestore: AngularFirestore,
    private ClientesService: ClientesService,
  
  ) {}

  AgregarDeuda(deuda: any) {
    return this.firestore.collection('deudas').add(deuda);
  }

  ObtenerDeudas() {
    return this.firestore
      .collection('deudas', (ref) => ref.orderBy('fecha_ingreso', 'desc').limit(50))
      .valueChanges({ idField: 'id' });
  }

  CargarTodasDeudas(){

    return this.firestore
    .collection('deudas', (ref) => ref.orderBy('fecha_ingreso', 'desc'))
    .valueChanges({ idField: 'id' });

  }

  EliminarDeuda(id: string) {
    this.firestore.doc('deudas/' + id).delete();
  }

  ActualizarDeuda(id: string, deuda: Deuda) {
    return this.firestore.collection('deudas').doc(id).set(deuda);
  }

  ObtenerDeudaCliente(id_cliente: string) {
    return this.firestore
      .collection('deudas', (ref) => ref.where('cliente', '==', id_cliente))
      .valueChanges();
  }

}