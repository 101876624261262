import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';



@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() {}

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}.xlsx`;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    XLSX.writeFile(workbook, ExcelService.toExportFileName(excelFileName));
  }


  public backupExcelFile(clientes:any[], deudas:any[], pagos:any[], excelFileName:string) {

    let wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(clientes), "clientes");

    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(deudas), "deudas");

    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(pagos), "pagos");

    XLSX.writeFile(wb, ExcelService.toExportFileName(excelFileName));
  }

}
