import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, combineLatest, concat, forkJoin, from } from 'rxjs';
import { concatMap, delay, first, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Cliente } from '../interfaces/cliente';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {


  year = new Date().getFullYear().toString()
  mes = new Intl.DateTimeFormat('es-ES', {
    month: 'long',
  }).format(new Date());

  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
  constructor(
    private firestore: AngularFirestore,
    private http:HttpClient,
    private datePipe: DatePipe
    ) { }

  AgregarCliente(cliente:Cliente){
    return this.firestore.collection("clientes").add(cliente)
  }

  ObtenerClientes(){
    return this.firestore.collection("clientes", ref => ref.orderBy('estado', 'desc').orderBy('fecha_ingreso','asc')).valueChanges({idField: 'id'})
  }
  ObtenerClientesConDeuda(){
    return this.firestore.collection("clientes", ref => ref.where('deuda', '>', 0)).valueChanges({idField: 'id'})
  }

  ObtenerCliente(id:string) {
    return this.firestore
    .collection('clientes')
    .doc(id)
    .valueChanges({idField: 'id'})
  }

  EliminarCliente(id:string){
    this.firestore.doc('clientes/' + id).delete();
  }

  ActualizarCliente(id:string,cliente:any){
    return this.firestore.doc('clientes/' + id).update(cliente)
  }

  ObtenerClienteConNombre(nombre:string){
    return this.firestore.collection("clientes", ref => ref.where('cliente', '==', nombre)).valueChanges({idField:'id'})
  }


  obtenerMesCliente(idCliente:string){
    return this.firestore.collection("clientes").doc(idCliente).collection('meses').doc(this.year).valueChanges()
  }


  validateEmail(email:string){
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
  }

}
  

