import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmacionAgregarAbonosComponent } from 'src/app/components/confirmacion-agregar-abonos/confirmacion-agregar-abonos.component';
import { AuthService } from 'src/app/services/auth.service';
import { ClientesService } from 'src/app/services/clientes.service';
import { DeudasService } from 'src/app/services/deudas.service';
import { PagosService } from 'src/app/services/pagos.service';
import { ExcelService  } from '../../services/enviar-mails.service'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  data : any[] =[]

  constructor(
    private ExcelService :ExcelService , 
    private ClientesService:ClientesService, 
    private DeudasService:DeudasService,
    private PagosService:PagosService,
    private AuthService:AuthService, 
    private router:Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    
  }

  descargarExcel(){
    this.ClientesService.ObtenerClientes()
    .subscribe((data:any) => {
      
      
      data.forEach((element:any) => {
        delete element['fecha_ingreso'] 
      });
      this.data= data

      this.data.forEach(data => {
        data['mail_enviado'] = ""
      })
      console.log(this.data)
      const mesActual = new Intl.DateTimeFormat('es-ES', { month: 'long'}).format(new Date())
      this.ExcelService.exportAsExcelFile(this.data, `Abonos ${mesActual}`)
      
    })
  }


  logout(){
    this.AuthService.logout()
    this.router.navigate(['/login'])
  }


  backup(){
    combineLatest([this.ClientesService.ObtenerClientes(), this.DeudasService.CargarTodasDeudas(), this.PagosService.CargarTodosLosPagos() ]).pipe(take(1))
    .subscribe(([clientes, deudas, pagos]:any) => {
      console.log("clientes", clientes)
      console.log("pagos", pagos)
      console.log("deudas", deudas)

      // console.log(new Date())
      var f = new Date();
      var hoy = f.getDate() + "-"+ f.getMonth()+ "-" +f.getFullYear();
      this.ExcelService.backupExcelFile(clientes, deudas, pagos, "Backup Satelital Oeste " + hoy)
    })
  }

  // agregarCliente(){
  //   this.ClientesService.agregarClienteDefault()
  //   .subscribe(data =>{
  //     console.log(data)
  //   })
  // }


}
