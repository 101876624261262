import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {first, map } from 'rxjs/operators'
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private AuthService:AuthService, private router:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.AuthService.getUserLogged()
      .pipe(
        // take the first value emitted and complete
        first(),
        map((value:any) => {
            if(value) {
              // this.router.navigate([''])
              return true;
                
            }
            this.router.navigate(['/login'])
            return false;
        })
      )
  }
  
}
