// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //Satelital Original
  // firebaseConfig :{
  //   apiKey: "AIzaSyBn_GHzyvwQew7ELVKB4hDkwKZ7b5YyuA4",
  //   authDomain: "satelitaloeste-cebce.firebaseapp.com",
  //   projectId: "satelitaloeste-cebce",
  //   storageBucket: "satelitaloeste-cebce.appspot.com",
  //   messagingSenderId: "1065480410013",
  //   appId: "1:1065480410013:web:e7da566657f339f44d1574"
  // }


  //satelital Prueba
  firebaseConfig :{
    apiKey: "AIzaSyBKS-KzrHAY3yghsLdCisp6snU0DgigEUg",
    authDomain: "satelital-prueba.firebaseapp.com",
    projectId: "satelital-prueba",
    storageBucket: "satelital-prueba.appspot.com",
    messagingSenderId: "848326631993",
    appId: "1:848326631993:web:b45522ef230a08ae0d23e8"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
