import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PagosService {

  constructor(private firestore: AngularFirestore) { }

  AgregarPago(pago:any){
    return this.firestore.collection("pagos").add(pago)
  }

  ObtenerPagos(){
    return this.firestore.collection("pagos", ref => ref.orderBy('fecha_ingreso', 'desc').limit(50)).valueChanges({idField: 'id'})
  }

  CargarTodosLosPagos(){
    return this.firestore.collection("pagos", ref => ref.orderBy('fecha_ingreso', 'desc')).valueChanges({idField: 'id'})
  }

  EliminarPago(id:string){
    this.firestore.doc('pagos/' + id).delete();
  }

  ActualizarPago(id:string,pago:any){
    return this.firestore.collection('pagos').doc(id).set(pago);
  }

  ObtenerPagoCliente(id_cliente:string){
    return this.firestore.collection('pagos', ref => ref.where('cliente', '==', id_cliente)).valueChanges()
  }
}
