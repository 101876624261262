<p style="margin-bottom: 0px;">
  <mat-toolbar color="primary">
    <button (click)="drawer.toggle()" mat-icon-button class="example-icon"
      aria-label="Example icon-button with menu icon">
      <mat-icon>menu</mat-icon>
    </button>
    <span>Satelital Oeste</span>
  </mat-toolbar>
</p>

<mat-drawer-container class="full" autosize>
  <mat-drawer class="width" #drawer mode="side" opened="true">

    <mat-list>
      <div mat-subheader>Información</div>
      <mat-list-item>
        <button class="button" mat-button routerLink="" [routerLinkActiveOptions]="{exact:true}"
          routerLinkActive="active">
          Tabla clientes
        </button>


      </mat-list-item>
      <mat-list-item>
        <button class="button" mat-button routerLink="/deudas" routerLinkActive="active">
          Tabla Deudas
        </button>
      </mat-list-item>

      <mat-list-item>
        <button class="button" mat-button routerLink="/pagos" routerLinkActive="active">
          Tabla Pagos
        </button>
      </mat-list-item>
      <mat-divider></mat-divider>
      <div mat-subheader>Acciones</div>
      <mat-list-item>
        <button class="button" mat-button (click)="backup()">
          Descargar  backup  excel
        </button>
        
      </mat-list-item>

      <mat-list-item>
        <button class="button" (click)="logout()" mat-button >
          Cerrar sesión
        </button>
        
      </mat-list-item>


      


    </mat-list>

  </mat-drawer>
  <mat-drawer-content>

    <!-- Content -->
    <router-outlet>
    </router-outlet>
    <!-- end Content -->

  </mat-drawer-content>

</mat-drawer-container>
